
import { defineComponent, PropType } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';

interface Link {
  term: string;
  path: string;
}

export default defineComponent({
  name: 'LegalLinkCard',
  props: {
    link: { type: Object as PropType<Link>, required: true },
  },
  setup() {
    const { t } = useTranslations();
    return { t };
  },
});
