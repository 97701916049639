import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LegalInfoBlock = _resolveComponent("LegalInfoBlock")!
  const _component_LegalLinksBlock = _resolveComponent("LegalLinksBlock")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_LegalInfoBlock),
    _createVNode(_component_LegalLinksBlock)
  ]))
}