import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4404f58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "links-container mb-5 lg:mb-8" }
const _hoisted_3 = { class: "links-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_LegalLinkCard = _resolveComponent("LegalLinkCard")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "container" }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseText, {
          variant: "17-18",
          class: "mb-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('legal_b2b_title')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legalLinksB2B, (link) => {
            return (_openBlock(), _createBlock(_component_LegalLinkCard, {
              key: link.term,
              link: link
            }, null, 8, ["link"]))
          }), 128))
        ]),
        _createVNode(_component_BaseText, {
          variant: "17-18",
          class: "mb-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('legal_b2c_title')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legalLinksB2C, (link) => {
            return (_openBlock(), _createBlock(_component_LegalLinkCard, {
              key: link.term,
              link: link
            }, null, 8, ["link"]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}