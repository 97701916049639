
import { defineComponent } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { routes } from '@/shared/constants/routes';
import LegalLinkCard from './LegalLinkCard.vue';
import { legalLinksB2B, legalLinksB2C } from '@/shared/constants/legal';

export default defineComponent({
  name: 'LegalLinksBlock',
  components: { LegalLinkCard },
  setup() {
    const { t } = useTranslations();
    return { t, routes, legalLinksB2B, legalLinksB2C };
  },
});
