import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createBlock(_component_BaseContainer, { class: "container" }, {
    default: _withCtx(() => [
      _createVNode(_component_BackButton, {
        to: _ctx.routes.home.path,
        class: "back-button"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseHeading, {
            mobileSmaller: "",
            size: 4,
            class: "ml-1 lg:ml-2.5"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('legal_back_btn')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_BaseText, {
        variant: "20-25",
        class: "text-block"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('legal_disclaimer_1')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseText, {
        variant: "20-25",
        class: "text-block"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('legal_disclaimer_2')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseText, {
        variant: "20-25",
        class: "text-block"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('legal_disclaimer_3')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}