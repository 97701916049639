export const legalLinksB2B = [
  {
    term: 'legal_link_sale_conditions',
    path: 'https://www.kurt-koenig.de/site/assets/files/1392/kkoenig_avb_04-2020_1c.pdf',
  },
  {
    term: 'legal_link_rent_conditions',
    path: 'https://www.kurt-koenig.de/site/assets/files/1392/allgemeine_mietbedingungen_2018.pdf',
  },
  {
    term: 'legal_link_flex_rent_conditions',
    path:
      'https://www.kurt-koenig.de/site/assets/files/1392/flexmiete_mietbedingungen_gewerbl__kunden.pdf',
  },
  {
    term: 'legal_link_repair_conditions',
    path:
      'https://www.kurt-koenig.de/site/assets/files/1392/allgemeine_reparatur-_und_montagebedingen_06-2012_unternehmer.pdf',
  },
  {
    term: 'legal_link_additional_conditions',
    path:
      'https://www.kurt-koenig.de/site/assets/files/1392/garantiebedingungen_fur_gerate_zusatzgarantien_-_produktprogramm_liebherr_stand_11_2016.pdf',
  },
];

export const legalLinksB2C = [
  {
    term: 'legal_link_payment_and_delivery',
    path:
      'https://www.kurt-koenig.de/site/assets/files/1392/allgemeine_zahungs-und_lieferungsbedingungen_kk_2012.pdf',
  },
  {
    term: 'legal_link_rent_conditions_b2c',
    path:
      'https://www.kurt-koenig.de/site/assets/files/1392/allgmietbedingungen2012_verbraucher.pdf',
  },
  {
    term: 'legal_link_repair_conditions_b2c',
    path:
      'https://www.kurt-koenig.de/site/assets/files/1392/allgemeine_reparaturbedingungen_verbraucher_2012.pdf',
  },
];
