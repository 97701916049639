import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c74d0ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("a", {
    class: "card",
    href: _ctx.link.path
  }, [
    _createVNode(_component_BaseText, {
      variant: "18-20",
      class: "block font-bold break-words"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t(`${_ctx.link.term}`)), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseText, { variant: "17-18" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('legal_link_btn')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseIcon, {
        icon: "arrow",
        class: "icon"
      })
    ])
  ], 8, _hoisted_1))
}